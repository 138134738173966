@import url("https://fonts.googleapis.com/css?family=Lato:400,700");
html {
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
  position: relative;
  font-family: "Lato", sans-serif;
  color: #505962;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  background-color: white;
  background-image: url('background.jpg');
  background-attachment: fixed;
}
.text-uppercase {
  letter-spacing: 0.08em;
}
@font-face {
  font-family: Bodoni_FLF;
  src: url('Bodoni_FLF_Regular.ttf');
}
@font-face {
  font-family: Allura-Regular;
  src: url('Allura-Regular.ttf');
}
@font-face {
  font-family: CAC_Champagne;
  src: url('cac_champagne.ttf');
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uXFxzdHlsZXMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBUSxtRUFBQTtBQUVSO0VBQ0Usa0JBQUE7QUFBRjtBQUdBO0VBQ0ksa0JBQUE7RUFDQSxrQkFBQTtFQUNBLCtCQUFBO0VBQ0EsY0FBQTtFQUNBLGdCQUFBO0VBQ0EsZUFBQTtFQUNBLGVBQUE7RUFDQSx1QkFBQTtFQUNBLHVDQUFBO0VBQ0EsNEJBQUE7QUFBSjtBQUdBO0VBQ0ksc0JBQUE7QUFBSjtBQUdBO0VBQ0UsdUJBQUE7RUFDQSxrQ0FBQTtBQUFGO0FBR0E7RUFDRSwyQkFBQTtFQUNBLDhCQUFBO0FBREY7QUFJQTtFQUNFLDBCQUFBO0VBQ0EsNkJBQUE7QUFGRiIsImZpbGUiOiJzdHlsZXMuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgdXJsKCdodHRwczovL2ZvbnRzLmdvb2dsZWFwaXMuY29tL2Nzcz9mYW1pbHk9TGF0bzo0MDAsNzAwJyk7XHJcblxyXG5odG1sIHtcclxuICBvdmVyZmxvdy14OiBoaWRkZW5cclxufVxyXG5cclxuYm9keSB7XHJcbiAgICBvdmVyZmxvdy14OiBoaWRkZW47XHJcbiAgICBwb3NpdGlvbjogcmVsYXRpdmU7XHJcbiAgICBmb250LWZhbWlseTogJ0xhdG8nLCBzYW5zLXNlcmlmO1xyXG4gICAgY29sb3I6ICM1MDU5NjI7XHJcbiAgICBtaW4taGVpZ2h0OiAxMDAlO1xyXG4gICAgbWluLXdpZHRoOiAxMDAlO1xyXG4gICAgbWF4LXdpZHRoOiAxMDAlO1xyXG4gICAgYmFja2dyb3VuZC1jb2xvcjogd2hpdGU7XHJcbiAgICBiYWNrZ3JvdW5kLWltYWdlOiB1cmwoXCJhc3NldHMvaW1hZ2VzL2JhY2tncm91bmQvYmFja2dyb3VuZC5qcGdcIik7XHJcbiAgICBiYWNrZ3JvdW5kLWF0dGFjaG1lbnQ6IGZpeGVkXHJcbn1cclxuXHJcbi50ZXh0LXVwcGVyY2FzZSB7XHJcbiAgICBsZXR0ZXItc3BhY2luZzogMC4wOGVtXHJcbn1cclxuXHJcbkBmb250LWZhY2Uge1xyXG4gIGZvbnQtZmFtaWx5OiBCb2RvbmlfRkxGO1xyXG4gIHNyYzogdXJsKCdhc3NldHMvZm9udHMvQm9kb25pX0ZMRl9SZWd1bGFyLnR0ZicpXHJcbn1cclxuXHJcbkBmb250LWZhY2Uge1xyXG4gIGZvbnQtZmFtaWx5OiBBbGx1cmEtUmVndWxhcjtcclxuICBzcmM6IHVybCgnYXNzZXRzL2ZvbnRzL0FsbHVyYS1SZWd1bGFyLnR0ZicpXHJcbn1cclxuXHJcbkBmb250LWZhY2Uge1xyXG4gIGZvbnQtZmFtaWx5OiBDQUNfQ2hhbXBhZ25lO1xyXG4gIHNyYzogdXJsKCdhc3NldHMvZm9udHMvY2FjX2NoYW1wYWduZS50dGYnKVxyXG59XHJcbiJdfQ== */