@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

html {
  overflow-x: hidden
}

body {
    overflow-x: hidden;
    position: relative;
    font-family: 'Lato', sans-serif;
    color: #505962;
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
    background-color: white;
    background-image: url("assets/images/background/background.jpg");
    background-attachment: fixed
}

.text-uppercase {
    letter-spacing: 0.08em
}

@font-face {
  font-family: Bodoni_FLF;
  src: url('assets/fonts/Bodoni_FLF_Regular.ttf')
}

@font-face {
  font-family: Allura-Regular;
  src: url('assets/fonts/Allura-Regular.ttf')
}

@font-face {
  font-family: CAC_Champagne;
  src: url('assets/fonts/cac_champagne.ttf')
}
